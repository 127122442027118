/** @module OK/cookie */
const SECOND_MS = 1000;
const MINUTE_MS = SECOND_MS * 60;
const HOUR_MS = MINUTE_MS * 60;
const DAY_MS = HOUR_MS * 24;

enum ExpireTimeUnit {
    Day,
    Hour,
    Minute,
    Second
}

const timeUnitsMap = {
    [ExpireTimeUnit.Day]: DAY_MS,
    [ExpireTimeUnit.Hour]: HOUR_MS,
    [ExpireTimeUnit.Minute]: MINUTE_MS,
    [ExpireTimeUnit.Second]: SECOND_MS,
};

/**
 * @param {string} name
 * @param {string} value
 * @param {?number} expires
 * @param {?string} path
 * @param {?string} domain
 * @param {?ExpireTimeUnit} timeUnit
 */
function setCookie(name: string, value: string, expires?: number, path?: string, domain?: string, timeUnit: ExpireTimeUnit = ExpireTimeUnit.Day): void {
    let expiresValue: string = "";
    if (0 === expires) {
        expiresValue = ";expires=Thu, 01 Jan 1970 00:00:00 GMT"
    } else if (expires) {
        expires = expires * timeUnitsMap[timeUnit];
        let date: Date = new Date();
        date.setTime(date.getTime() + (expires));
        expiresValue = ";expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value)
        + expiresValue
        + ((path) ? ";path=" + path : "")
        + ((domain) ? ";domain=" + domain : "");
}

/**
 * @param {string} name
 * @return {string|null}
 */
function readCookie(name: string): string|null {
    const
        /** @type {string} */
        nameEQ: string = name + "=",
        /** @type {string[]} */
        cookies: string[] = document.cookie.split(';');
    for (let idx: number = 0; idx < cookies.length; idx++) {
        let cookie: string = cookies[idx];
        while (cookie.charAt(0) === ' ') {
            /* Убираем пробелы */
            cookie = cookie.substring(1);
        }
        if (cookie.startsWith(nameEQ)) {
            return cookie.substring(nameEQ.length);
        }
    }
    return null;
}

/**
 * @param {string} name
 * @param {string} path
 */
function clearCookie(name: string, path: string): void {
    setCookie(name, "", 0, path);
}

/**
 * @param {string} name
 * @param {string} value
 * @param {?string} attributes
 */
function storeCookie(name: string, value: string, attributes?: string): void {
    document.cookie = name + "=" + encodeURIComponent(value) + (attributes ? attributes : "");
}

export {
    setCookie,
    readCookie,
    clearCookie,
    storeCookie,
    DAY_MS,
    HOUR_MS,
    MINUTE_MS,
    SECOND_MS,
    ExpireTimeUnit
}